import { IAttrComponent, IAttributeData } from "../../common/attribute/Attribute";
import { IComponentFieldInfo } from "./EditorComponentMgr";

export type ComponentInstance = m4m.framework.INodeComponent | m4m.framework.I2DComponent | m4m.framework.transform | m4m.framework.transform2D;

export abstract class ComponentFieldHandler<T extends IAttrComponent<any>> {

    public type: string;

    public constructor(type: string) {
        this.type = type;
    }

    public abstract render(component: ComponentInstance, fieldInfo: IComponentFieldInfo): IAttributeData<T>[];
}