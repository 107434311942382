import {IEvent} from "./IEvent";
import {IEventBinder} from "./IEventBinder";

/**
 * 事件绑定对象
 */
export class EventBinder<T = any, K extends keyof T = any, F extends T[K] = any> implements IEventBinder {
    
    public constructor(public mgr: IEvent<T>, public key: K, public func: F) {
    }

    /**
     * 移除当前事件监听
     */
    public removeListener() {
        this.mgr.removeEventListener(this as any);
    }
}