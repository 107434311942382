export enum ComponentFieldEnum {
    HideInInspector = "",
    Number = "number",
    String = "string",
    Boolean = "boolean",
    Vector2 = "vector2",
    Vector3 = "vector3",
    Rect = "rect",
    Border = "border",
    Layout = "layout",
}